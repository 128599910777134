<template>
    <travio-center-container grid-width='full'>
      <h2 style="color:#636363" class="mb-1">Exchange Rate Manager</h2>
      <vs-breadcrumb :separator="'chevron_right'" class="mb-3">
        <li>
          <router-link class="vs-breadcrumb--link" :to="`/applications/${applicationId}/currencyExchange`">Exchange Rate Manager</router-link>
          <span aria-hidden="true" class="separator notranslate vs-breadcrum--separator material-icons">
            chevron_right
          </span>
        </li>
        <li>
          <span class="vs-breadcrumb--text vs-breadcrumb-text-primary">Exchange Rate Manager</span>
        </li>
      </vs-breadcrumb>
      <vx-card class="exchange-rate">
       
         <ag-grid-vue
          ref="agGridTable"
          :components="cellRendererComponents"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="exchangeRates"
          :gridOptions="gridOptions"
          :autoGroupColumnDef="autoGroupColumnDef"
          rowSelection="single"
          :pagination="false"
          :context="context"
          :headerHeight="100"
          :groupHeaderHeight="40"
          :suppressPaginationPanel="true"
          @first-data-rendered="onFirstDataRendered"
          @grid-ready="onGridReady"
        >
        </ag-grid-vue>
        
        <div class="vx-row">
          <div class="vx-col w-full">
            <vs-button class="mr-4 sm:mb-0 mb-2" color="primary" @click="onSave" type="filled">Update</vs-button>      
              
          </div>
        </div>
        
      </vx-card>
    
    </travio-center-container>
  </template>
  
  <script>
      
  import {AgGridVue} from "ag-grid-vue";
  import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
  import Vue from "vue"
  import useAppFeatureAccess from '@/components/travio-pro/useAppFeatureAccess.js';
  import SellRateCustomHeader from './components/SellRateCustomHeader.vue';
  import BoostRateCustomHeader from './components/BoostRateCustomHeader.vue';
  import BuyBackRateCustomHeader from './components/BuyBackRateCustomHeader.vue';
  import ApiRateCustomHeader from './components/ApiRateCustomHeader.vue';

  export default {
    components: {
      AgGridVue,
      SellRateCustomHeader,
      BoostRateCustomHeader,
      ApiRateCustomHeader,
      BuyBackRateCustomHeader,
    },
    props: {
      applicationId: { required: true}
    },
    data () {
      return {
        exchangeRates: [],
        exchangeRatesPerCompany: [],
        autoGroupColumnDef: null,
        gridOptions: null,
        gridApi: null,
        columnApi: null,
        columnDefs: null,
        defaultColDef: null,
        rowSelection: null,
        cellRendererComponents: {},
        context: null,
        searchQuery: '',
        nestedData: null,
      }
    },
    computed: {
      activeUserInfo() {
        return this.$store.state.AppActiveUser;
      },
    },
    setup () {
      const { appHasFeature } = useAppFeatureAccess();
      return { appHasFeature }
    },
    async created () {
      //Check if user has acccess to the app
      if( !(this.activeUserInfo.applications && this.activeUserInfo.applications.find(x => x.id == this.applicationId)) ) {
        this.$router.push('/error-404')
      }
  
      if (!this.appHasFeature('currencyexchange')) {
        this.$router.push('/error-404')
      }
      // this.exchangeRates = [
      //   { 
      //     exchangeRateId: 1, 
      //     currencyId: 2, 
      //     currencyCode: 'USD', 
      //     smallestDenomination: 100, 
      //     availableStock: 20, 
      //     apiRate: 1.13456, 
      //     sellRate_0: 1.1459,  
      //     boostRate_0: 1.1589, 
      //     sellRate_1: 1.1359,  
      //     boostRate_1: 1.1489 
      //   },
      //   { 
      //     exchangeRateId: 2, 
      //     currencyId: 3, 
      //     currencyCode: 'EUR', 
      //     smallestDenomination: 100, 
      //     availableStock: 20, 
      //     apiRate: 1.13456, 
      //     sellRate_0: 1.2459,  
      //     boostRate_0: 1.3589, 
      //     sellRate_1: 1.2359,  
      //     boostRate_1: 1.2489 
      //   },
      // ]
  
      this.$vs.loading()
  
      try {
        const response = await this.getApiData()
        this.nestedData = response.data
        this.addDynamicColumns(response.data)
        const flattenedData = this.flattenData(response.data)
        const pivotedData = this.pivotData(flattenedData)
        this.exchangeRates = pivotedData
      } catch (error) {
        this.$_notifyFailure(error)
      }
  
      this.$vs.loading.close();
  
    },

    beforeMount() {
  
      this.gridOptions = {};
  
      // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
      this.columnDefs =  [
        { field: 'exchangeRateId', hide: true },
        { field: 'currencyId', hide: true },
        { headerName: 'Currency', field: 'currencyCode', width: 120, suppressMovable: true,  suppressSizeToFit: true, pinned: 'left'},
        { headerName: 'Smallest Denom.', field: 'smallestDenomination', width: 180, suppressMovable: true,  suppressSizeToFit: true, pinned: 'left' },
        { headerName: 'Available Stock', field: 'availableStock', width: 160, suppressMovable: true, suppressSizeToFit: true, pinned: 'left' },
        { headerName: 'API Rate', field: 'apiRate', headerComponentFramework: ApiRateCustomHeader, width: 180, suppressMovable: true, suppressSizeToFit: true, pinned: 'left', cellStyle: { textAlign: 'right' } },
        { headerName: 'Sell Markup', field: 'sellMarkup', width: 160, suppressMovable: true, suppressSizeToFit: true, pinned: 'left', cellStyle: { textAlign: 'right' } },
        { headerName: 'Boost Markup', field: 'boostMarkup', width: 160, suppressMovable: true, suppressSizeToFit: true, pinned: 'left', cellStyle: { textAlign: 'right' } },
      ]
      

      this.defaultColDef = {
        flex: 1,
        minWidth: 60,
        wrapHeaderText: true,
        autoHeaderHeight: false,
        resizable: true,
        suppressMenu: true
      }
  
      this.autoGroupColumnDef = { minWidth: 200 };
      
      //This will make methods from this accessible to cell renderer
      this.context = { componentParent: this }
  
    },
    mounted() {
      this.gridApi = this.gridOptions.api;
      this.gridColumnApi = this.gridOptions.columnApi;
      // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
      this.gridApi.sizeColumnsToFit();
    },
    methods: {
      getApiData() {
        return this.$http.get(`api/currencyExchanges/apps/${this.applicationId}/rates`)
      },
      async getCurrentRates() {
        try {
          this.$vs.loading()
          await this.$http.post(`api/currencyExchanges/apps/${this.applicationId}/rates/apiRate`)
          this.refreshGridData()
          this.$_notifySuccess('Successfully updated exchange rates');
        } catch (error) {
          this.$_notifyFailureByResponseData(error.response)
        } finally {
          this.$vs.loading.close()
        }
      
      },
      onGridReady () {
        this.gridApi.sizeColumnsToFit && this.gridApi.sizeColumnsToFit()
      },
      onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
      },
      flattenData(data) {
        // Create a map of currencies by their ID for quick lookup
        const currencyMap = data.currencies.reduce((map, currency) => {
            map[currency.id] = currency;
            return map;
        }, {});

        // Flatten the structure
        const flattenedData = [];

        data.companies.forEach(company => {
            company.exchangeRates.forEach(exchangeRate => {
              const currency = currencyMap[exchangeRate.currencyId];
              flattenedData.push({
                companyId: company.id,
                companyName: company.name,
                currencyId: currency.id,
                currencyCode: currency.code,
                currencyName: currency.name,
                smallestDenomination: currency.smallestDenomination,
                availableStock: currency.availableStock,
                apiRate: currency.apiRate,
                minAmountForBoost: currency.minAmountForBoost,
                orderId: currency.orderId,
                isActive: currency.isActive,
                exchangeRateId: exchangeRate.rateId, // Include rateId
                sellMarkup: currency.sellMarkup,
                boostMarkup: currency.boostMarkup,
                standardRate: exchangeRate.standardRate,
                boostRate: exchangeRate.boostRate,
                buyBackRate: exchangeRate.buyBackRate,
              });
            });
        });

        return flattenedData;
      },
      pivotData (flattenedData) {
        const pivotedData = [];
        const currencies = {};
        flattenedData.forEach(item => {
          const { currencyId, currencyCode, smallestDenomination, availableStock, apiRate, companyId, standardRate, boostRate, buyBackRate, sellMarkup, boostMarkup } = item;
          
          if (!currencies[currencyCode]) {
              currencies[currencyCode] = {
                  currencyId,
                  currencyCode,
                  smallestDenomination,
                  availableStock,
                  apiRate,
                  sellMarkup,
                  boostMarkup,
              };
          }
          
          currencies[currencyCode][`standardRate_${companyId}`] = standardRate;
          currencies[currencyCode][`boostRate_${companyId}`] = boostRate;
          currencies[currencyCode][`buyBackRate_${companyId}`] = buyBackRate;
        });

        for (const currency in currencies) {
            pivotedData.push(currencies[currency]);
        }

        return pivotedData;
      },
      addDynamicColumns(nestedData) {
        nestedData && nestedData.companies && nestedData.companies.forEach(company => {
          this.columnDefs.push({ 
            headerName: `${company.name}`, headerClass: 'ag-center-header',
            headerComponentParams : { companyId: company.id, companyName: company.name },
            children: [
              { 
                headerName: 'Sell Rate', 
                field: `standardRate_${company.id}`,
                editable: true,
                cellStyle: { textAlign: 'right', 'border-right': '1px solid rgba(0, 0, 0, .2)' },
                valueParser: params => Number(params.newValue) || null,
                width: 130,
                suppressMovable: true,
                suppressSizeToFit: true,
                headerComponentFramework: SellRateCustomHeader,
                headerComponentParams : { companyId: company.id, companyName: company.name } 
              },
              { 
                headerName: 'Boost Rate', 
                field: `boostRate_${company.id}`, 
                editable: true,
                cellStyle: { textAlign: 'right', 'border-right': '1px solid rgba(0, 0, 0, .2)' },
                valueParser: params => Number(params.newValue) || null,
                width: 130, 
                suppressMovable: true,
                suppressSizeToFit: true,
                headerComponentFramework: BoostRateCustomHeader,
                headerComponentParams : { companyId: company.id, companyName: company.name } },
              { 
                headerName: 'Buy-Back Rate', 
                field: `buyBackRate_${company.id}`, 
                editable: true,
                cellStyle: { textAlign: 'right', 'border-right': '1px solid rgba(0, 0, 0, .2)' },
                valueParser: params => Number(params.newValue) || null,
                width: 150, 
                suppressMovable: true,
                suppressSizeToFit: true,
                headerComponentFramework: BuyBackRateCustomHeader,
                headerComponentParams : { companyId: company.id, companyName: company.name } },
            ]
          })
        })
      },
      async onSave () {
        // update nestedData based on grid data
        this.nestedData.companies.forEach( (company, companyIndex, companyArray) => {
          const companyId = company.id

          company.exchangeRates.forEach( (rate, index, arr) => {
            const gridData = this.exchangeRates.find(x => x.currencyId == rate.currencyId)
            rate.standardRate = gridData[`standardRate_${companyId}`]
            rate.boostRate = gridData[`boostRate_${companyId}`]
            rate.buyBackRate = gridData[`buyBackRate_${companyId}`]
            arr[index] = rate
          })

          companyArray[companyIndex] = company
        })

        // Save this.nestedData.companies to API
        this.$vs.loading()
        try {
          await this.$http.post(`api/currencyExchanges/apps/${this.applicationId}/rates`, { companies: this.nestedData.companies })
          // Reload data so the rate ids relfect
          this.refreshGridData()
          this.$_notifySuccess('Successfully updated exchange rates');
        } catch (error) {
          this.$_notifyFailureByResponseData(error.response)
        } finally {
          this.$vs.loading.close()
        }
      },
      async refreshGridData() {
        const response = await this.getApiData()
        this.nestedData = response.data
        const flattenedData = this.flattenData(response.data)
        const pivotedData = this.pivotData(flattenedData)
        this.exchangeRates = [...pivotedData]
      },
      addSellMarkup(companyId){
        this.exchangeRates.forEach( (rate, index, arr) => {
          rate[`standardRate_${companyId}`] = (rate.apiRate + rate.sellMarkup).toFixed(4)
          arr[index] = rate
        })
        this.exchangeRates = [...this.exchangeRates]
      },
      addBoostMarkup(companyId) {
        this.exchangeRates.forEach( (rate, index, arr) => {
          rate[`boostRate_${companyId}`] = (rate.apiRate + rate.boostMarkup).toFixed(4)
          arr[index] = rate
        })
        this.exchangeRates = [...this.exchangeRates]
      },
      addBuyBackMarkup(companyId, markup){
        this.exchangeRates.forEach( (rate, index, arr) => {
          rate[`buyBackRate_${companyId}`] = rate.apiRate.toFixed(4)
          arr[index] = rate
        })
        this.exchangeRates = [...this.exchangeRates]
      },
    }
  }
  </script>
  
  <style>
  .ag-center-header .ag-header-group-text {
    margin: 0 auto !important;
  }

  .ag-tall-header {
    height: 90px !important;
  }

  .exchange-rate .ag-header-cell {
    border-right: 1px solid #dae1e7;
  }

  .exchange-rate .ag-header-group-cell-with-group {
    border-right: 1px solid #dae1e7;
  }
  </style>