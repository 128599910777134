<template>
  <div>
    <div class="flex flex-wrap justify-center">Boost Rate</div>
    <div class="flex flex-wrap justify-center">
      <vs-button class="mt-2 w-full" size="small" color="primary" @click="onApply" type="border">Apply</vs-button>      
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
  export default Vue.extend({
    components: { },
    props: [],
    data() {
      return {
      }
    },
    beforeMount() {
    },
    computed: {
    },
    methods: {
      onApply () {
        this.params.context.componentParent.addBoostMarkup(this.params.companyId)
      },
    }
  })
</script>